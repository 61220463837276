<template>
  <b-card v-if="sellerData">
    <div class="d-flex">
      <feather-icon icon="FileTextIcon" size="19" />
      <h4 class="mb-0 ml-50">
        {{ $t('admin.labs.sellerForm.generalInfo') }}
      </h4>
    </div>

    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form
        class="mt-1"
        @submit.prevent="
          handleSubmit(
            submit(sellerData, giveMeasurements, removeMeasurements, qtyMeas),
          )
        "
      >
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                :label="$t('admin.labs.sellerForm.field1')"
                label-for="nameRegistered"
              >
                <b-form-input
                  v-model="sellerData.nameRegistered"
                  class="form-control"
                  :placeholder="$t('admin.labs.sellerForm.placeholder1')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider
              #default="validationContext"
              name="Seller email"
              rules="required|email"
            >
              <b-form-group
                :label="$t('admin.labs.sellerForm.field2')"
                label-for="adminEmail"
              >
                <b-form-input
                  v-model="sellerData.userEmail"
                  id="adminEmail"
                  :placeholder="$t('admin.labs.sellerForm.placeholder2')"
                  :plaintext="$route.params.id !== 'new'"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- allowedMeasurements -->
        <b-col cols="12" v-if="!isSuperAdmin">
          <b-row align-v="center">
            <span class="mr-1"
              >Esta entidade usou
              <strong>{{
                `${sellerData.qtyMeasurements}/${
                  sellerData.allowedMeasurements || 0
                }`
              }}</strong>
              medidas</span
            >
            <b-button
              class="mr-1"
              :variant="giveMeasurements ? 'secondary' : 'outline-secondary'"
              @click="
                showAllowedMeasurementTextField = true
                giveMeasurements = true
                removeMeasurements = false
              "
            >
              Adicionar medições
            </b-button>
            <b-button
              class="mr-1"
              :variant="removeMeasurements ? 'warning' : 'outline-warning'"
              @click="
                showAllowedMeasurementTextField = true
                removeMeasurements = true
                giveMeasurements = false
              "
            >
              Remover medições
            </b-button>
          </b-row>
          <b-row align-v="center" v-if="showAllowedMeasurementTextField">
            <validation-provider
              #default="validationContext"
              name="Quantity"
              :rules="`required|${
                giveMeasurements
                  ? `between:0,${userAdminDoc.availableMeasurements}`
                  : removeMeasurements
                  ? `between:0,${
                      (sellerData.allowedMeasurements || 0) -
                      sellerData.qtyMeasurements
                    } `
                  : ''
              }`"
            >
              <b-form-group label="Quantity" label-for="quantity">
                <b-form-input
                  v-model="qtyMeas"
                  class="form-control"
                  placeholder="0"
                  :state="getValidationState(validationContext)"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div v-if="userAdminDoc">
              <strong class="ml-1" v-if="giveMeasurements">
                {{ `${userAdminDoc.availableMeasurements} Disponíveis` }}
              </strong>
              <strong class="ml-1" v-if="removeMeasurements">
                <span v-if="sellerData">
                  {{
                    `${
                      sellerData.allowedMeasurements -
                      sellerData.qtyMeasurements
                    } Disponíveis para retirada`
                  }}
                </span>
              </strong>
            </div>
          </b-row>
        </b-col>

        <b-row class="mt-2 pr-1" align-h="end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :to="{ name: 'sellers' }"
          >
            {{ $t('admin.labs.sellerForm.cancel') }}
          </b-button>
          <b-button
            type="submit"
            :variant="invalid ? 'secondary' : 'primary'"
            :disabled="loading || invalid"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <b-spinner v-if="loading" small></b-spinner>

            <span v-else>{{ $t('admin.labs.sellerForm.save') }}</span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import { onUnmounted } from 'vue-demi'
import vSelect from 'vue-select'

import { computed } from '@vue/composition-api'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, email, positive } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import store from '@/store'
import router from '@/router'

import creditStoreModule from '../credits/creditStoreModule'
import sellerStoreModule from './sellerStoreModule'
import useSellerForm from './useSellerForm'
import SellerEntity from '../../../entities/seller.entity'
import RoleEnum from '@/utils/roles'
import { CreditEntity } from '../../../entities/credit.entity'
import ReasonEnum from '../../../utils/credits'

export default {
  name: 'SellerForm',

  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,
    BSpinner,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      integer,
      email,
      positive,
      showAllowedMeasurementTextField: false,
      giveMeasurements: false,
      removeMeasurements: false,
      qtyMeas: 0,
    }
  },

  computed: {
    userAdminDoc() {
      return this.$store.state.auth.userAdminDoc
    },
    isSuperAdmin() {
      return this.$store.state.auth.user.role === RoleEnum.SUPER_ADMIN
        ? true
        : false
    },
  },

  watch: {
    'sellerData.active'(data, oldData) {
      if (data === oldData || this.loading) {
        return
      }
    },
  },

  setup() {
    const SELLER_APP_STORE_MODULE_NAME = 'seller'
    const CREDIT_APP_STORE_MODULE_NAME = 'credit'

    if (!store.hasModule(SELLER_APP_STORE_MODULE_NAME)) {
      store.registerModule(SELLER_APP_STORE_MODULE_NAME, sellerStoreModule)
    }

    if (!store.hasModule(CREDIT_APP_STORE_MODULE_NAME)) {
      store.registerModule(CREDIT_APP_STORE_MODULE_NAME, creditStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(SELLER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SELLER_APP_STORE_MODULE_NAME)

      store.hasModule(CREDIT_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(CREDIT_APP_STORE_MODULE_NAME)
    })

    const { sellerData, resetData, loadData, onSubmit } = useSellerForm()

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData)

    const id = router.currentRoute.params.id
    const parent = router.currentRoute.query.p

    if (id !== 'new') {
      loadData(`${parent}/sellers/${id}`)
    }

    const loading = computed({
      get: () => store.state['seller'].loading,
    })

    /**
     * Submits the form data.
     *
     * @param {SellerEntity} data the data to be submitted.
     */
    const submit = (data, giveMeasurements, removeMeasurements, qtyMeas) => {
      const user = store.state.auth.user
      const ownerRef = user.adminDocRef || user.sellerDocRef

      const credit = new CreditEntity({
        ownerRef,
        reason: ReasonEnum.ASSIGNMENT,
      })

      if (!data.allowedMeasurements) {
        data.allowedMeasurements = 0
      }

      const userAdminDoc = store.state.auth.userAdminDoc

      if (giveMeasurements) {
        data.allowedMeasurements =
          parseInt(data.allowedMeasurements || 0) + parseInt(qtyMeas || 0)

        credit.difference = -parseInt(qtyMeas)
        credit.remaining = !userAdminDoc
          ? null
          : parseInt(userAdminDoc.availableMeasurements) - parseInt(qtyMeas || 0)
      } else if (removeMeasurements) {
        data.allowedMeasurements =
          parseInt(data.allowedMeasurements || 0) - parseInt(qtyMeas || 0)

        credit.difference = parseInt(qtyMeas || 0)
        credit.remaining = !userAdminDoc
          ? null
          : parseInt(userAdminDoc.availableMeasurements) + parseInt(qtyMeas || 0)
      }

      onSubmit(
        new SellerEntity({
          ...data,
        }),
        parent,
        credit,
      )
    }

    return {
      sellerData,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      submit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__deselect svg {
  fill: #fff;
}

.dark-layout .vs__deselect svg {
  fill: #7367f0;
}
</style>
