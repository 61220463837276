import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

import store from '@/store'
import router from '@/router'

import SellerEntity from '@/entities/seller.entity'
import AddressModel from '@/models/address.model'
import RoleEnum from '@/utils/roles'
import AuthLinkEntity from '@/entities/auth-link.entity'
import AuthController from '@/controllers/auth.controller'
import { CreditEntity } from '../../../entities/credit.entity'

export default function useSellerForm() {
  const toast = useToast()

  const blankData = new SellerEntity({
    nameFantasy: '',
    nameRegistered: '',
    userEmail: '',
    adminId: '',
    status: 'pending',
    qtyMeasurements: 0,
    address: new AddressModel({
      line1: '',
      line2: '',
      country: '',
      number: null,
      zipCode: '',
    }),
  })

  const sellerData = ref(new SellerEntity(blankData))

  const subscription = ref(null)

  /**
   * Loads a seller based on the given id.
   *
   * @param {string} id the seller id.
   */
  const loadData = async (path) => {
    try {
      sellerData.value = await store.dispatch('seller/fetchOneSeller', path)
    } catch (e) {
      console.log(e)
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.seller_form.failure.fetching'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  /**
   * Resets the seller data.
   */
  const resetData = () => {
    sellerData.value = new SellerEntity(blankData)
  }

  /**
   * Sets the given data into the database.
   *
   * @param {SellerEntity} data the data to be set.
   * @param {string} parent the parent document path.
   * @param {CreditEntity} credit the credit to be created.
   */
  const onSubmit = async (data, parent, credit) => {
    const id = router.currentRoute.params.id

    let alId

    if (id === 'new') {
      const userExists = await new AuthController().checkIfUserExists(
        data.userEmail,
      )
      const userWasInvited = await new AuthController().checkIfEmailWasInvited(
        data.userEmail,
      )
      if (userExists || userWasInvited) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('firebase.error.emailInUse'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return
      }
    }

    try {
      const path = id !== 'new' ? `${parent}/sellers/${id}` : parent
      const result = await store.dispatch('seller/setSeller', {
        data,
        id: id !== 'new' ? id : null,
        parent: path,
      })

      if (id !== 'new') {
        credit.targetRef = path

        router.push({ name: 'sellers' })

        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toast.sellerForm.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        store.dispatch('credit/create', credit)
        return
      } else {
        credit.targetRef = `${path}/sellers/${result.id || result.data.id}`
        store.dispatch('credit/create', credit)
      }

      const authLink = new AuthLinkEntity({
        email: data.userEmail,
        relationPath: result.relationPath,
        role: RoleEnum.SELLER,
        plan: null,
        isTrialPlan: false,
        trialDays: null,
      })

      const res = await store.dispatch('auth/createAuthLink', authLink)
      alId = res.id

      await store.dispatch('auth/sendSignInLink', {
        email: data.userEmail,
        authLinkId: alId,
      })

      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.sellerForm.success'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      router.push({ name: 'sellers' })
    } catch (e) {
      console.log(e)
      if (alId) {
        store.dispatch('auth/deleteAuthLink', alId)
      }

      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.sellerForm.failure.creating'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    sellerData,
    resetData,
    loadData,
    onSubmit,
    subscription,
  }
}
